<!-- 用户编辑弹窗 -->
<template>
  <a-modal :width="680" :visible="visible" :confirm-loading="loading" @update:visible="updateVisible">
    <template #footer>
      <a-space>
        <a-button @click="handleCancle"> 取消 </a-button>
        <a-button @click="downloadClick">下载</a-button>
        <a-button type="danger" @click="commentOpen('forwarded')" v-if="form.processState === 'received'" v-permission="'jyyw:heroRecommend:forwarded'"> 转发
        </a-button>
        <a-button type="primary" @click="commentOpen('agree')" v-permission="'jyyw:heroRecommend:approve'"> 确认
        </a-button>
        <a-button type="danger" @click="commentOpen('refuse')" v-permission="'jyyw:heroRecommend:approve'"> 不予确认
        </a-button>
      </a-space>
    </template>
    <a-form class="ud-form-detail" ref="form" :model="form" :rules="rules">
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title">基本信息</a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="12" :sm="12" :xs="24">
          <a-form-item label="申请人:" name="name">
            {{ form.name }}
          </a-form-item>
          <a-form-item label="申请人身份证:" name="idcard">
            {{ form.idcard }}
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="12" :xs="24">
          <a-form-item label="申请人手机号:" name="phone">
            {{ form.phone }}
          </a-form-item>
          <a-form-item label="事件日期:" name="date">
            {{ form.date }}
          </a-form-item>
        </a-col>
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="事件地点:" name="address">
            {{ form.address }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title">见义勇为行为人</a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16" v-for="(item, index) in heros" :key="index">
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="姓名:" name="phone">
            {{ item.name }}
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="身份证:" name="iccard">
            {{ item.idcard }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title">详细事件描述</a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-textarea allow-clear type="textarea" placeholder="详细事件描述" v-model:value="form.description"
            style="width: 100%; height: 120px" disabled class="disabledColor">
          </a-textarea>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title">相关证明材料</a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-upload list-type="picture-card" v-model:file-list="fileList" @preview="handlePreview" disabled>
            <div v-if="fileList.length < 1">
              <plus-outlined />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title">处理状态：{{ form.processStateName }}</a-form-item>
        </a-col>
      </a-row>
      <div class="divComments">
        <a-row :gutter="16" v-for="(item, index) in form.comments" :key="index">
          <a-col :md="24" :sm="24" :xs="24">
            {{ item.time }}, {{ item.userName || '厦门市见义勇为促进会' }}
          </a-col>
          <a-col :md="24" :sm="24" :xs="24">
            <div class="divComment">
              {{ item.comment }}
            </div>
          </a-col>
        </a-row>
      </div>
    </a-form>
    <a-modal :title="commentForm.title" :width="500" :visible="commentShow" @cancel="commentCancel()"
      @ok="commentSave()">
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24" style="height: 30px">
          <span style="color:red"> * {{ commentForm.subTitle }}</span>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24" style="height: 100px">
          <a-textarea v-model:value="commentForm.comment" placeholder="内容" :rows="4" />
        </a-col>
      </a-row>
    </a-modal>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import * as recommendApi from '@/api/jyyw/recommend'
import moment from 'moment'
// function getBase64(pointPhoto) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(pointPhoto)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }
export default {
  name: 'applicationDetail',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      fileList: [],
      heros: [
        {
          name: '',
          idcard: ''
        }
      ],
      tipsValue: '',
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {},
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [],
      previewVisible: false,
      previewImage: '',
      commentShow: false,
      commentForm: {
        title: '',
        subTitle: '',
        state: '',
        comment: ''
      }
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.getData(this.data.id)
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.getresultDictdata()
  },
  methods: {
    // 结果提示语
    selectClick(options) { },
    getData(id) {
      recommendApi.getById(id).then(res => {
        this.form = Object.assign({}, res.data)
        if (this.form.heros) {
          this.heros = JSON.parse(this.form.heros)
        }
        if (this.form.attachment) {
          const items = JSON.parse(this.form.attachment)
          // eslint-disable-next-line no-unused-vars
          let i = 0
          this.fileList = items.map((item) => {
            i++
            return {
              uid: '-2',
              name: 'image.png',
              status: 'done',
              url: item
            }
          })
          console.log(items)
        }
        if (this.form.processState === 'received') {
          this.form.processUserName = this.$store.state.user.user.nickname
          this.form.processTime = moment().format('YYYY-MM-DD HH:mm:ss')
        }
      })
    },
    // 下载
    downloadClick() {
      this.loading = true
      recommendApi
        .download(this.form.id)
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            const url = setting.downloadUrl + res.data
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute(
              'download',
              this.form.name + '-' + this.form.code + '.zip'
            )
            document.body.appendChild(link)
            link.click()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    handleCancle() {
      this.updateVisible(false)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 查询电池协议
    getresultDictdata() {
      recommendApi
        .resultDictdata('result')
        .then((res) => {
          if (res.code === 0) {
            this.resultList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询角色列表 */
    queryRoles() {
      this.$http
        .get('/sys/role')
        .then((res) => {
          if (res.data.code === 0) {
            this.roleList = res.data.data
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    removeFile(file) {
      console.log(file)
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'pointPhoto') {
              this.pointPhotoList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'businessLicense') {
              this.businessLicenseList[0] = {
                uid: '-3',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'idcardPic') {
              this.idcardPicList[0] = {
                uid: '-2',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'idcardPic2') {
              this.idcardPic2List[0] = {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            }
            this.form[name] = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    handlePreview(file) {
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    handleCancel() {
      this.previewVisible = false
    },
    commentOpen(state) {
      this.commentShow = true
      this.commentForm.comment = ''
      this.commentForm.state = state
      if (state === 'forwarded') {
        this.commentForm.title = '转发材料'
        this.commentForm.subTitle = '请确认已下载并转发，确定之后处理状态将变成"已转发"'
      } else if (state === 'agree') {
        this.commentForm.title = '行为已确认'
        this.commentForm.subTitle = '确定之后处理状态将变成"行为已确认"'
      } else if (state === 'refuse') {
        this.commentForm.title = '行为不予确认'
        this.commentForm.subTitle = '确定之后处理状态将变成"行为不予确认"'
      }
    },
    commentCancel() {
      this.commentShow = false
      this.comment = ''
    },
    commentSave() {
      this.loading = true
      const data = {
        processResult: this.commentForm.comment,
        id: this.form.id
      }
      if (this.commentForm.state === 'forwarded') {
        recommendApi.forwarded(data)
          .then((res) => {
            this.loading = false
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.commentShow = false
              this.getData(data.id)
              this.updateVisible(false)
              this.$emit('done')
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.loading = false
            this.$message.error(e.message)
          })
      } else if (this.commentForm.state === 'agree') {
        if (this.commentForm.comment === '') {
          this.loading = false
          this.$message.error('内容不能为空')
          return
        }
        recommendApi.agree(data)
          .then((res) => {
            this.loading = false
            if (res.code === 0) {
              this.commentShow = false
              this.$message.success(res.msg)
              this.getData(data.id)
              this.updateVisible(false)
              this.$emit('done')
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.loading = false
            this.$message.error(e.message)
          })
      } else if (this.commentForm.state === 'refuse') {
        if (this.commentForm.comment === '') {
          this.loading = false
          this.$message.error('内容不能为空')
          return
        }
        recommendApi.refuse(data)
          .then((res) => {
            this.loading = false
            if (res.code === 0) {
              this.commentShow = false
              this.$message.success(res.msg)
              this.getData(data.id)
              this.updateVisible(false)
              this.$emit('done')
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.loading = false
            this.$message.error(e.message)
          })
      }
    }
  }
}
</script>

<style scoped lang='less'>
.ud-form-detail {
  :deep(.ant-descriptions-title) {
    padding-top: 10px;
  }

  :deep(.disabledColor) {
    background: #fff;
    opacity: 1;

    textarea[disabled] {
      color: black;
      cursor: text;
    }
  }

  .divComments {
    overflow: hidden;
    max-height: 200px;
    overflow-y: auto;
  }

  .divComment {
    padding: 5px 5px 5px 10px;
    margin: 5px 0 0;
    background-color: rgba(0, 0, 0, .025);
    border: 1px solid #eee;
  }
}
</style>
